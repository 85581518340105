<template>
  <div class="kids-container">
    <div class="kids-header">
      <h2>{{ title }}</h2>
      <button
        class="form"
        @click="addKid"
        v-if="!isAdminPage"
      >Inscrire un enfant</button>
    </div>
    <div class="sub-line">
      <div class="group">
        <label for="orderBy">Trier par</label>
        <select
          v-model="sort"
          id="orderBy"
          class="form"
        >
          <option value="name">Nom</option>
          <option value="grade">Classe</option>
        </select>
      </div>
      <div class="status-container">
        <div class="group">
          <div class="status"></div>
          <span>Non validé</span>
        </div>
        <div class="group">
          <div class="status verified"></div>
          <span>Validé</span>
        </div>
      </div>
    </div>
    <div class="kids-grid">
      <div
        v-for="(kid, idx) in kidsPagination[pageIndex]"
        :key="idx"
        class="kid"
        :class="{ verified: kid.verified }"
        @click="enrollKid(kid)"
      >
        <div class="infos">
          <span class="name">{{ kid.firstname }}&nbsp;{{ kid.lastname }}</span>
          <span class="birthdate">{{ convertTimestampToString(kid.birthdate.seconds) }}</span>
          <span class="grade">{{ grades[Number(kid.grade)] }}</span>
          <template v-if="isAdminPage && getUsers.length">
            <span
              v-for="parent in kid.parent"
              :key="`parent-${idx}-${parent}`"
              class="parents"
            >{{ getParent(parent) }}</span>
          </template>
        </div>
        <div class="actions">
          <export-logs v-if="isAdminPage" :kid="kid" />
          <font-awesome-icon
            v-if="isAdminPage"
            :icon="['fas', 'user-edit']"
            class="edit"
            @click.stop="editKid(kid)"
          />
          <div class="status" :class="{ verified: kid.verified }"></div>
        </div>
      </div>
    </div>
    <div class="pagination" v-if="totalPages > 1">
      <button
        class="form"
        @click="prevPage"
      >Page précédente</button>
      <button
        class="form"
        @click="nextPage"
      >Page suivante</button>
    </div>
    <create-edit-kid ref="createEditKid" :kid="kid" />
    <enroll-kid ref="enrollKid" :kid="kid" :active-school-year="getActiveSchoolYear" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateEditKid from '@/components/CreateEditKid.vue'
import EnrollKid from '@/components/EnrollKid.vue'
import ExportLogs from '@/components/ExportLogs.vue'

export default {
  name: 'ListKids',
  components: {
    CreateEditKid,
    EnrollKid,
    ExportLogs
  },
  props: {
    kids: {
      type: Array,
      default: () => [],
      required: true
    },
    title: {
      type: String,
      default: () => 'Mes enfants'
    }
  },
  data() {
    return {
      kid: null,
      grades: [
        'Petite Section',
        'Moyenne Section',
        'Grande Section',
        'CP',
        'CE1',
        'CE2',
        'CM1',
        'CM2',
      ],
      pageIndex: 0,
      sort: 'name',
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'getUsers']),
    ...mapGetters('schoolyears', ['getActiveSchoolYear']),
    ...mapGetters('layout', ['getInnerWidth']),
    isAdminPage() {
      return this.$route.name === 'Admin'
    },
    kidsPagination() {
      let kidsPage = []
      let kids = this.lodash.cloneDeep(this.kids)

      if (this.sort === 'name') {
        kids.sort((a, b) => {
          return a.verified === b.verified ? 0 : a.verified ? 1 : -1 ||
            a.lastname.localeCompare(b.lastname, 'fr', { sensitivity: 'base' }) ||
            a.firstname.localeCompare(b.firstname, 'fr', { sensitivity: 'base' })
        })
      } else if (this.sort === 'grade') {
        kids.sort((a, b) => {
          return a.verified === b.verified ? 0 : a.verified ? 1 : -1 ||
            a.grade - b.grade ||
            a.lastname.localeCompare(b.lastname, 'fr', { sensitivity: 'base' }) ||
            a.firstname.localeCompare(b.firstname, 'fr', { sensitivity: 'base' })
        })
      }

      if (this.getInnerWidth < 600) {
        while (kids.length > 5) {
          kidsPage.push(kids.splice(0,5))
        }
      } else if (this.getInnerWidth < 900) {
        while (kids.length > 10) {
          kidsPage.push(kids.splice(0,10))
        }
      } else {
        while (kids.length > 15) {
          kidsPage.push(kids.splice(0,15))
        }
      }

      if (kids.length > 0) {
        kidsPage.push(kids)
      }

      return kidsPage
    },
    totalPages() {
      return this.kidsPagination.length
    }
  },
  methods: {
    ...mapActions('schoolyears', ['fetchActiveSchoolYear']),
    editKid(kid) {
      this.kid = kid
      this.$nextTick(() => {
        this.$refs.createEditKid.showModal()
      })
    },
    addKid() {
      this.kid = null
      this.$nextTick(() => {
        this.$refs.createEditKid.showModal()
      })
    },
    enrollKid(kid) {
      if (!kid.verified) return

      this.kid = kid
      this.$nextTick(() => {
        this.$refs.enrollKid.showModal()
      })
    },
    pad(s) {
      return (s < 10) ? '0' + s : s
    },
    convertTimestampToString(date) {
      date = new Date(date * 1000)
      return [
        this.pad(date.getDate()),
        this.pad(date.getMonth()+1),
        date.getFullYear(),
      ].join('/')
    },
    prevPage() {
      if (this.pageIndex > 0)
        this.pageIndex -= 1
    },
    nextPage() {
      if (this.pageIndex < this.totalPages - 1)
        this.pageIndex += 1
    },
    getParent(uid) {
      const parent = this.getUsers.find(parent => parent.uid === uid)
      return `${parent.firstname} ${parent.lastname}`
    }
  },
  created() {
    this.fetchActiveSchoolYear()
  }
}
</script>

<style lang="scss" scoped>
div.kids-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  div.kids-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;

    h2 {
      font-size: 20px;
    }

    button {
      margin: 0;
    }
  }

  div.kids-grid {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 600px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  div.kid {
    width: 100%;
    padding: 5px 10px;
    background: #f6f6f6;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 2px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 15px;

      @media screen and (min-width: 600px) {
        margin-bottom: 0;
      }
    }

    &.verified {
      cursor: pointer;
      background: white;
    }

    div.infos {
      display: flex;
      flex-direction: column;
      
      span.birthdate,
      span.grade,
      span.parents {
        font-weight: 300;
      }
    }

    div.actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg.edit {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }

  div.sub-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    label {
      white-space: nowrap;
      margin-right: 5px;
    }

    select {
      margin-bottom: 0;
    }
  }

  div.group {
    display: flex;
    align-items: center;
  }

  div.status-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div.group {
      &:not(:last-of-type) {
        margin-right: 15px;
      }

      div.status {
        margin-right: 5px;
      }
    }
  }

  div.status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: radial-gradient(lightsalmon 30%, transparent);
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ff5b1f;
    }

    &.verified {
      background: radial-gradient(palegreen 30%, transparent);

      &:after {
        background-color: #2faa2f;
      }
    }
  }

  div.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>